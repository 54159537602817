<template>
  <div class="tixian">
    <div class="content">
      <div class="title">{{ $t('兑换币种') }}</div>
      <div class="input-wrap" @click="show = true">
        <div v-if="select" class="select-label">{{ select.text }}</div>
        <img src="@/assets/img/base/down.png" width="12" />
      </div>
      <div class="title q-mt-md">{{ $t('兑换数量') }}</div>
      <div class="input-wrap">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div>
      <div class="balance" v-if="wlist.length > 0">
        <div>
          <!-- <div class="row items-center">
            <div class="q-mr-sm">STC{{ $t('价格') }}：{{ stcPrice }}</div>
            (<van-count-down :time="30000" format="mm:ss" class="count-down" ref="countDownRef" @finish="finish" />)
          </div> -->
          <div v-if="select">{{ $t('可用') }}{{ select.coinname1 }}：{{ wlist.filter(f=>f.cid == select.cid1)[0].jine }}</div>
          <div v-if="select">{{ $t('获得') }}{{ select.coinname2 }}：{{ (num * polPrice).toFixed(6) }}</div>
        </div>
        <div class="all-btn" @click="num = wlist.filter(f=>f.cid == select.cid1)[0].jine">{{ $t('全部2') }}</div>
      </div>
      <div class="btn" @click="submit">{{ $t('确认兑换') }}</div>
      <div class="link-text">
        <span @click="tolink('ZhuanhuanRecord')">{{ $t('兑换记录') }}</span>
      </div>
    </div>

    <div class="cp-popup">
      <van-popup v-model:show="show" position="bottom">
        <van-picker :confirm-button-text="$t('确定')" :cancel-button-text="$t('取消')" :columns="selectList"
          @confirm="onConfirm" @cancel="show = false" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Picker, Popup, CountDown } from 'vant';

import Web3Helper from "@/assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [CountDown.name]: CountDown,
  },
  setup() {
    return {
      num: ref(""),
      address: ref(""),
      us: ref({}),
      zhid: ref(null),
      selectList: ref([]),
      select: ref({}),
      show: ref(false),
      wlist: ref([]),

      polPrice: ref(0)
    }
  },
  created() {
    // this.zhid = this.$route.query.zhid
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getSelect()
      this.getStcPrice()
    }
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    rightClick() {
      this.$router.push('TixianRecord')
    },
    onConfirm(e) {
      this.select = e
      this.zhid = e.id
      this.show = false
    },
    finish() {
      this.getStcPrice()
      this.$refs.countDownRef.reset()
    },
    getStcPrice() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/GetPrice",
        {
          coinName: 'POL'
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          _this.polPrice = data
        }
      )
    },
    getSelect() {
      let _this = this
      _this.$request.post(
        "api/WalletsZhuanhuan/ZhuanhuanSelect",
        {
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          _this.wlist = data.wlist
          data.zlist.forEach(item => {
            item.text = `${item.coinname1}${_this.$i18n.t("兑换")}${item.coinname2}` 
          })
          _this.selectList = data.zlist
          if (data.zlist.length > 0) {
            let select = data.zlist.find(item => item.id == _this.zhid)
            _this.select = select ? select : data.zlist[0]
            _this.zhid = _this.select.id
          }
        }
      )
    },
    submit() {
      let _this = this
      if (!this.select) {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请选择货币"],
            persistent: true,
          }
        });
        return
      }
      if (this.num == "") {
        this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要转换吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let web3Helper = new Web3Helper();
        this.$q.loading.show({
          message: _this.$i18n.t("转换中...")
        })
        web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/WalletsZhuanhuan/Zhuanhuan",
            {

              userid: _this.us.userid,
              jine: _this.num,
              zhid: _this.zhid,
              signStr: signStr,
              address: _this.us.address
            },
            (res) => {
              _this.$q.loading.hide();
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [res.data.msg],
                  persistent: true,
                }
              });
              if (res.data.code == 100) {
                _this.select = null
                _this.getSelect()
              }
            }
          )
        }).catch(() => {
          _this.$q.loading.hide();
        })
      })
    }
  }
}
</script>

<style scoped>
.tixian {
  margin-top: 10px;
  padding: 15px;
  border-radius: 20px;
  background: #121C2E;
}

.title {
  color: #8c95a7;
  font-size: 12px;
}

.input-wrap {
  margin-top: 5px;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 99px;
  background-color: #000;
  color: #fff;
}

.balance {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8c95a7;
  font-size: 12px
}

.all-btn {
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #fff;
  background-color: #D51B8C;
}

.line {
  margin-top: 20px;
  height: 5px;
  background: url(~@/assets/img/base/fenge.png) no-repeat;
  background-size: 100% 100%;
}

.other-cell {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.btn {
  margin-top: 40px;
  height: 48px;
  line-height: 48px;
  border-radius: 99px;
  text-align: center;
  background: #D51B8C;
  color: #fff;
  font-weight: 15px;
}

.cp-popup /deep/ .van-picker {
  background: #121C2E !important;
}

.cp-popup /deep/ .van-picker__mask {
  background-size: 100% 0 !important;
}

.cp-popup /deep/ .van-picker__frame::after {
  border-top: 1px solid rgba(85, 80, 80, 0.6);
  border-bottom: 1px solid rgba(85, 80, 80, 0.6);
}

.cp-popup /deep/ .van-picker-column__item {
  color: #fff;
}

.cp-popup /deep/ .van-picker__confirm {
  color: #884DDA;
}

.cp-popup /deep/ .van-picker__title {
  color: #fff;
}

.link-text {
  text-align: center;
  margin-top: 15px;
  color: #8c95a7;
  text-decoration: underline;
  font-size: 13px;
}

.count-down {
  color: #8c95a7;
}
</style>
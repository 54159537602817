<template>
  <div class="wallet">
    <headbar />

    <div class="container">
      <div class="total-row">
        <div class="total-item">
          <div class="total-item-text">{{ $t('未释放') }}</div>
          <div class="total-item-value">
            <view>{{ pageData.shifang }}</view>
          </div>
        </div>
        <div class="total-item">
          <div class="total-item-text">{{ $t('每日释放') }}</div>
          <div class="total-item-value">
            <view>{{ pageData.shifangDay }}</view>
          </div>
        </div>
        <div class="total-item">
          <div class="total-item-text">{{ $t('链游总赢额') }}</div>
          <div class="total-item-value">
            <view>{{ pageData.jingcaiWin }}</view>
          </div>
        </div>
        <div class="total-item">
          <div class="total-item-text">{{ $t('每日链游赢额') }}</div>
          <div class="total-item-value">
            <view>{{ pageData.jingcaiWinDay }}</view>
          </div>
        </div>
      </div>

      <!-- <div class="total-row q-mt-lg">
        <div class="total-item" v-for="item in walletList" :key="item.id">
          <div class="total-item-text">{{ $t(item.cnameZh) }}</div>
          <div class="total-item-value">
            <view>{{ item.jine }}</view>
          </div>
        </div>
      </div> -->


      <div class="operate">
        <div class="operate-card">
          <div class="tabs">
            <div class="tabs-item" v-for="(item, index) in tabs" :key="index" @click="tabsClick(index)">
              {{ $t(item.text) }}
            </div>
          </div>
          <div class="wallet-list">
            <div class="wallet-item" v-for="item in walletList" :key="item.id">
              <div class="row items-center">
                <img src="@/assets/img/wallet/stx.png" class="wallet-icon" v-if="item.cid == 4" />
                <img src="@/assets/img/wallet/stc.png" class="wallet-icon" v-else-if="item.cid == 3" />
                <img src="@/assets/img/wallet/matic.png" class="wallet-icon" v-else />
                <div>{{ $t(item.cnameZh) }}</div>
                <Touzi v-if="item.cid == 1" :polAmount="item.jine" :polxAmount="walletList.find(item => item.cid == 5).jine" :us="us" @onSubmit="getWallet">
                  <template #btn>
                    <div class="zhuanhuan">{{ $t('入金') }}</div>
                  </template>
                </Touzi>
                <STXtoPOT v-if="item.cid == 4" :stxAmount="item.jine" :us="us" @onSubmit="getWallet">
                  <template #btn>
                    <div class="zhuanhuan">{{ $t('转') }}POT</div>
                  </template>
                </STXtoPOT>
              </div>
              <div>{{ item.jine }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="show">
      <div class="full-width">
        <!-- 充值 -->
        <recharge v-if="active == 0" />
        <!-- 提现 -->
        <withdrawal v-if="active == 1" />
        <!-- 转账 -->
        <Transfer v-if="active == 2" />
        <!-- 转换 -->
        <Convert v-if="active == 3" />
      </div>
    </q-dialog>

    <check-register @refresh="refresh"></check-register>

    <tab-bar active="3" />
  </div>
</template>

<script>
  import { ref } from 'vue'
  import Recharge from './components/Recharge'
  import Withdrawal from './components/Withdrawal'
  import Transfer from './components/Transfer'
  import Convert from './components/Convert'
  import STXtoPOT from './components/STXtoPOT'
  import Touzi from './components/Touzi'
  import CustomComponent from "@/components/CustomComponent";
  import CheckRegister from '@/components/CheckRegister'

  export default {
    components: {
      Recharge,
      Withdrawal,
      Transfer,
      Convert,
      STXtoPOT,
      Touzi,
      CheckRegister
    },
    setup() {
      return {
        tabs: ref([
          { text: '充值' },
          { text: '提现' },
          { text: '转账' },
          { text: '兑换' },
        ]),
        active: ref(0),
        walletList: ref([]),
        us: ref(null),
        pageData: ref({}),
        show: ref(false)
      }
    },
    created() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      if (this.us) {
        this.getWallet()
        this.getUsPageData()
      }
    },
    methods: {
      refresh() {
        this.us = JSON.parse(this.$utils.getloc("us"))
        this.getWallet()
      },
      tabsClick(index) {
        this.active = index
        this.show = true
      },
      getUsPageData() {
        let _this = this
        _this.$request.post(
          "api/Page/UserPropertyList",
          {
            userid: _this.us.userid
          },
          (res) => {
            if (res.data.code == 100) {
              let data = res.data.data
              _this.pageData = data
            }
          }
        )
      },
      getWallet() {
        let _this = this
        _this.$q.loading.show({
          message: _this.$i18n.t("加载中...")
        })
        _this.$request.post(
          "api/Wallets/GetWalletsPageData",
          {
            userid: _this.us.userid
          },
          (res) => {
            _this.$q.loading.hide();
            if (res.data.code == 0) {
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [res.data.msg],
                  persistent: true,
                }
              });
              return
            }
            let data = res.data.data
            _this.walletList = data
          }
        )
      }
    }
  }
</script>

<style scoped>
  .wallet {
    min-height: 100vh;
    background-image: url(~@/assets/img/base/page-footer-bg.png);
    background-size: 100% 320px;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  .container {
    padding: 10px 19px;
  }

  .total-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
  }

  .total-item {
    margin-top: 8px;
    padding: 24px 15px 10px;
    width: 49%;
    min-height: 100px;
    border: 1px solid #2FCD0F;
    box-shadow: 0px 0px 12.6px 0px #2FCD0F inset;
    border-radius: 20px;
    color: #2FCD0F;
    background-color: #121C2E;
  }

  .total-item-text {
    font-size: 12px;
  }

  .total-item-value {
    margin-top: 5px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2FCD0F;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
  }

  .total-item:nth-child(2) {
    border: 1px solid #CD0FBA;
    box-shadow: 0px 0px 12.6px 0px #CD0FBA inset;
    color: #CD0FBA;
  }

  .total-item:nth-child(3) {
    border: 1px solid #0FBACD;
    box-shadow: 0px 0px 12.6px 0px #0FBACD inset;
    color: #0FBACD;
  }

  .total-item:nth-child(4) {
    border: 1px solid #884DDA;
    box-shadow: 0px 0px 12.6px 0px #884DDA inset;
    color: #884DDA;
  }

  .total-item:nth-child(2) .total-item-value {
    border: 1px solid #CD0FBA;
  }

  .total-item:nth-child(3) .total-item-value {
    border: 1px solid #0FBACD;
  }

  .total-item:nth-child(4) .total-item-value {
    border: 1px solid #884DDA;
  }

  .operate {
    margin-top: 8px;
    padding-top: 48px;
    background-image: url(~@/assets/img/base/ranking-round.png);
    background-size: 100% auto;
    background-position: top;
    background-repeat: no-repeat;
  }

  .operate-card {
    padding: 15px;
    background-color: #121C2E;
    border-radius: 20px;
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tabs-item {
    flex: 1;
    padding: 8px;
    margin: 0 5px;
    text-align: center;
    border-radius: 10px;
    font-size: 13px;
    color: #fff;
    background: #4E5F7E;
  }

  .tabs-item:nth-child(odd) {
    color: #fff;
    background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
  }

  .wallet-icon {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }

  .wallet-item {
    margin-top: 5px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #081121;
    border-radius: 10px;
    color: #fff;
    font-size: 13px;
  }

  .wallet-item:first-child {
    margin-top: 20px;
  }

  .zhuanhuan {
    margin-left: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 13px;
    color: #fff;
    background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
  }

  .touzi {
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 13px;
    color: #fff;
    background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
  }
</style>
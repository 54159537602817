<template>
    <q-dialog v-model="show">
        <div class="recharge">
            <div class="mintage">
                <div class="title">入金</div>
                <div class="input-wrap">
                    <q-input borderless v-model="amount" dense :input-style="{ color: '#fff' }" style="width: 100%;"
                        :placeholder="`${$t('请输入')}${coinName1}`" />
                </div>
                <div class="cell balance" style="margin-top: 15px;">
                    <div>{{ coinName1 }}: {{ amount * 0.8 }}</div>
                    <div>{{ $t('可用') }}{{ coinName1 }}：{{ polAmount }}</div>
                </div>
                <div class="cell balance" style="margin-top: 10px;">
                    <div>{{ coinName2 }}: {{ amount * 0.2 }}</div>
                    <div>{{ $t('可用') }}{{ coinName2 }}：{{ polxAmount }}</div>
                </div>
                <div class="row">
                    <div class="btn col q-mx-xs" @click="onSubmit">{{ $t('入金') }}</div>
                </div>
                <div class="link-text">
                    <span @click="tolink('TouziRecord')">{{ $t('入金记录') }}</span>
                </div>
            </div>
        </div>
    </q-dialog>
    <span @click="show = true">
        <slot name="btn"></slot>
    </span>
</template>

<script>
import { ref } from 'vue';
import Web3Helper from "@/assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

export default {
    components: {
    },
    setup() {
        return {
            coinName1: "POL",
            coinName2: "POLX",
            show: ref(false),
            amount: ref("")
        }
    },
    props: ['polAmount', 'us', 'polxAmount'],
    emits: ['onSubmit'],
    methods: {
        tolink(path) {
            this.$router.push(path)
        },
        onSubmit() {
            let _this = this
            if (_this.amount == "") {
                _this.$q.dialog({
                    component: CustomComponent,
                    componentProps: {
                        messages: [`请输入${_this.coinName1}`],
                        persistent: true,
                    }
                });
                return
            }
            this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                    messages: ['确定要入金吗?'],
                    persistent: true,
                    showCancel: true
                }
            }).onOk(() => {
                let web3Helper = new Web3Helper();
                this.$q.loading.show({
                    message: _this.$i18n.t("入金中...")
                })
                web3Helper.getSign().then((signStr) => {
                    _this.$request.post(
                        "api/Touzi/Touzi",
                        {
                            userid: _this.us.userid,
                            amount: _this.amount,
                            signStr: signStr,
                            address: _this.us.address
                        },
                        (res) => {
                            _this.$q.loading.hide();
                            _this.$q.dialog({
                                component: CustomComponent,
                                componentProps: {
                                    messages: [res.data.msg],
                                    persistent: true,
                                }
                            });
                            if (res.data.code == 100) {
                                _this.amount = ""
                            }
                            _this.show = false
                            _this.$emit('onSubmit')
                        }
                    )
                }).catch(() => {
                    _this.$q.loading.hide();
                })
            })
        }
    }
}
</script>

<style scoped>
.recharge {
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    border-radius: 20px;
    background: #121C2E;
}

.title {
    color: #8c95a7;
    font-size: 12px;
}

.consume {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.consume-icon {
    width: 15px;
    height: 15px;
}

.consume-title {
    margin-left: 5px;
    font-size: 13px;
    color: #fff;
}

.form {
    color: #fff;
}

.input-wrap {
    margin-top: 5px;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 99px;
    background-color: #000;
    color: #fff;
}

.input-label {
    font-size: 15px;
    color: #999;
}

.input-row {
    display: flex;
    align-items: center;
}

.stepper-icon {
    width: 22px;
}

.input {
    margin: 0 10px;
    width: 60px;
}

.mintage-text {
    color: #e96dff;
}

.cell {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
}

.btn {
    margin-top: 40px;
    height: 48px;
    line-height: 48px;
    border-radius: 99px;
    text-align: center;
    background: #D51B8C;
    color: #fff;
    font-weight: 15px;
}

.link {
    color: #e96dff;
    text-decoration: underline;
}

.select {
    color: #fff;
}

.select-list {
    display: flex;
    overflow-x: auto;
    padding: 10px 0;
}

.select-list-item {
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 180px;
    height: 240px;
    margin: 0 10px 10px 0;
    padding: 10px 0;
    border-radius: 5px;
    background-size: 100% 100%;
    background-image: url(../../../assets/img//touzi/border.png);
}

.active {
    background-size: 100% 100%;
    background-image: url(../../../assets/img//touzi/active.png);
}

.article {
    color: #fff;
    padding: 20px 0;
}

.text-body1 {
    font-size: 16px;
}

.text-body2 {
    font-size: 14px;
    margin-top: 10px;
}

.q-dialog-plugin {
    max-height: calc(100vh - 108px);
    background: #000;
    color: #fff;
    border: 2px solid #f56ef9;
}

.dialog-title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    padding: 16px;
}

.btn-row {
    display: flex;
    justify-content: space-between;
}

.btn-row .btn {
    width: 48%;
}

.balance {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #8c95a7;
    font-size: 12px
}

.link-text {
    text-align: center;
    margin-top: 15px;
    color: #8c95a7;
    text-decoration: underline;
    font-size: 13px;
}
</style>
<template>
  <q-dialog v-model="show" persistent>
    <q-card class="q-dialog-plugin" style="min-width: 350px">
      <q-card-section>
        <div class="text-body1">{{ $t('激活账号') }}</div>
        <div class="text-body2">{{ $t('请输入邀请码进行授权激活') }}</div>
        <!-- <div class="text-body2 text-right text-red" v-if="showTip">{{ $t('*无邀请码可直接注册！') }}</div> -->
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="input-wrap">
          <q-input borderless v-model="reAddress" dense :input-style="{ color: '#E96DFF' }" style="width: 100%;"
            :placeholder="$t('请输入邀请码')" />
        </div>
      </q-card-section>
      <div style="padding: 0 16px 16px;">
        <div class="btn" @click="register">{{ $t('注册') }}</div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import Web3Helper from "../assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {},
  props: {
    autoShowRegisterL: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      show: ref(false),
      web3Helper: ref({}),
      reAddress: ref(""),
      address: ref(""),
      us: ref(null),
      showTip: ref(true)
    }
  },
  created() {
    if (this.$route.query.reAddress) {
      this.reAddress = this.$route.query.reAddress;
      this.showTip = false
    }
    this.getAddress()
  },
  methods: {
    getAddress() {
      let _this = this;
      _this.web3Helper = new Web3Helper();
      _this.web3Helper.getAddress().then((address) => {
        _this.address = address;
        let loginCount = _this.$utils.getloc("loginCount")
        if (loginCount == 0 || !loginCount) {
          _this.login();
        }
      }, (err) => {
        alert(_this.$i18n.t("获取地址错误") + err.message);
      });
    },
    login() {
      let _this = this;
      _this.web3Helper.getSign().then((signStr) => {
        _this.$request.post(
          "Api/Users/Login",
          {
            address: _this.address,
            signStr: signStr
          },
          (res) => {
            if (res.data.code == 0) {
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  messages: [res.data.msg],
                  persistent: true,
                }
              })
              return
            }
            if (res.data.code == -1) {
              _this.show = true;
              return;
            }
            let data = res.data.data;
            _this.$utils.setloc("token", data.token);
            _this.$utils.setloc("us", JSON.stringify(data.users));
            this.$utils.setloc('loginCount', 1)

            //尝试连接到服务器
            // this.$ws.StartConnection(data.users.userid)

            _this.$emit("refresh")
          }
        );
      })
    },
    register() {
      let _this = this;
      if(this.reAddress == '') {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['请输入邀请码'],
            persistent: true,
          }
        })
        return
      }
      let cacheToken = Math.random();
      try {
        _this.web3Helper.getSign().then((signStr) => {
          _this.$request.post(
            "api/Users/Register", {
            cacheToken: cacheToken,
            recode: _this.reAddress,
            address: _this.address,
            signStr: signStr
          },
            (res) => {
              if (res.data.code == 0) {
                _this.$q.dialog({
                  component: CustomComponent,
                  componentProps: {
                    messages: [res.data.msg],
                    persistent: true,
                  }
                })
                return
              }
              _this.show = false
              _this.login()
            });
        }).catch((error) => {
          alert(`error:${error}`);
        })
      } catch (error) {
        alert(`error:${error}`);
      }
    },
  }
}
</script>

<style scoped>
.q-dialog-plugin {
  background: #3A3456;
  color: #fff;
}

.text-body1 {
  font-size: 16px;
}

.text-body2 {
  font-size: 14px;
  margin-top: 10px;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #221c35;
}

.btn {
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}
</style>
<template>
  <div class="recharge">
    <div class="mintage">
      <div class="title">{{ $t('充值数量') }}</div>
      <div class="input-wrap">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div>
      <div class="title q-mt-md">{{ $t('充值货币') }}</div>
      <div class="text-white">
        <q-radio v-model="lx" dark val="1" color="purple-14" label="POL" class="q-mr-md" />
        <q-radio v-model="lx" dark val="2" color="purple-14" label="STC" />
      </div>
      <div class="cell balance" style="margin-top: 15px;">
        <div>{{ $t('可用') }}POL：{{ balance.matic }}</div>
      </div>
      <div class="cell balance" style="margin-top: 15px;">
        <div>{{ $t('可用') }}STC：{{ balance.stc }}</div>
      </div>
      <div class="row">
        <!-- <div class="btn col q-mx-xs" v-if="userAllowance.lbd < parseFloat(num) || num==''" @click="approve(2)">{{ $t('授权金额') }}</div> -->
        <div class="btn col q-mx-xs" @click="chongzhi(lx)">{{ $t('充值') }}</div>
      </div>
      <div class="link-text">
        <span @click="tolink('ChongzhiRecord')">{{ $t('充值记录') }}</span>
      </div>
    </div>


    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card class="q-dialog-plugin" style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
          <div class="text-body2">Txid</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="input-wrap">
            <q-input borderless v-model="txid" dense :input-style="{ color: '#fff' }" style="width: 100%;" readonly>
              <template v-slot:append>
                <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(txid)" />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <div style="padding: 0 16px 16px;">
          <div class="btn" style="margin: 0;" @click="showTxid = false">{{ $t('确定') }}</div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import Web3Helper from "@/assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";
import useClipboard from 'vue-clipboard3';
import { RadioGroup, Radio } from 'vant';

import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";
export default {
  name: 'Touzi',
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  setup() {
    return {
      jine: ref(0),
      us: ref(null),
      touziSetting: null,
      active: ref(0),
      articleData: ref(""),

      contractInfoBNB: ref(""),
      contractInfoUSDT: ref(null),
      contractInfoLBD: ref(null),
      contractInfoLP: ref(null),
      contractInfoSTC: ref(null),
      contractInfoApproveContract: ref(null),
      contractInfoPayContract: ref(null),
      contractInfoSwapRouterV3: ref(null),

      web3Helper: ref({}),
      usdtWeb3Helper: ref({}),
      lbdWeb3Helper: ref({}),
      lpWeb3Helper: ref({}),
      stcWeb3Helper: ref({}),
      payContractWeb3Helper: ref({}),
      swapRouterV2Web3Helper: ref({}),
      swapRouterV3Web3Helper: ref({}),

      showTxid: ref(false),
      txid: ref(""),
      inAddress: ref(""),

      lx: ref("1"),

      bnbPrice: ref(0),

      num: ref(''), // 充值数量
      balance: ref({
        matic: 0,
        stc: 0
      })
    }
  },
  created() {
    // this.getSelect()
    this.load()
  },
  methods: {
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['Replicating Success'],
            persistent: true,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    onClick(index) {
      this.active = index
      this.jine = this.touziSetting.amounts[index]
    },
    tolink(path) {
      this.$router.push(path)
    },
    load() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      this.getInfo()
    },
    plus() {
      this.jine = parseFloat(this.jine) + 100
    },
    reduce() {
      if (this.jine > 0) {
        this.jine -= 100
        this.jine = this.jine < 0 ? 0 : this.jine
      }
    },
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/EthContract/Info",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data

          _this.web3Helper = new Web3Helper();

          _this.contractInfoSTC = data.contractList.find(item => item.id == 16);
          _this.stcWeb3Helper = new Web3Helper(_this.contractInfoSTC.abi, _this.contractInfoSTC.address);

          /* _this.contractInfoBNB = data.contractList.find(item => item.id == 5);

          _this.contractInfoUSDT = data.contractList.find(item => item.id == 1);
          _this.usdtWeb3Helper = new Web3Helper(_this.contractInfoUSDT.abi, _this.contractInfoUSDT.address);

          _this.contractInfoLBD = data.contractList.find(item => item.id == 14);
          _this.lbdWeb3Helper = new Web3Helper(_this.contractInfoLBD.abi, _this.contractInfoLBD.address);

          _this.contractInfoLP = data.contractList.find(item => item.id == 15);
          _this.lpWeb3Helper = new Web3Helper(_this.contractInfoLP.abi, _this.contractInfoLP.address);
          
          _this.contractInfoApproveContract = data.contractList.find(item => item.id == 102);

          _this.contractInfoPayContract = data.contractList.find(item => item.id == 103);
          _this.payContractWeb3Helper = new Web3Helper(_this.contractInfoPayContract.abi, _this.contractInfoPayContract.address);

          var swapRouterV2ContractInfo = data.contractList.find(item => item.id == 100)
          _this.swapRouterV2Web3Helper = new Web3Helper(swapRouterV2ContractInfo.abi, swapRouterV2ContractInfo.address);

          _this.contractInfoSwapRouterV3 = data.contractList.find(item => item.id == 101)
          _this.swapRouterV3Web3Helper = new Web3Helper(_this.contractInfoSwapRouterV3.abi, _this.contractInfoSwapRouterV3.address); */

          _this.inAddress = data.inAddress;
          _this.bnbPrice = _this.contractInfoBNB.priceIn

          _this.balanceOf(_this.us.address);
        }
      )
    },
    balanceOf(address) {
      let _this = this;
      _this.web3Helper.getBalance(address).then((wei) => {
        _this.balance.matic = wei;
      });
      _this.stcWeb3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance.stc = wei;
      })
    },
    chongzhi(lx) {
      let _this = this
      if (_this.num == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }

      if (lx == 1) {
        if (Number(_this.balance.matic) < Number(_this.num)) {
          _this.$q.dialog({
            component: CustomComponent,
            componentProps: {
              messages: ["钱包余额不足"],
              persistent: true,
            }
          });
          return
        }
      } else if (lx == 2) {
        if (Number(_this.balance.stc) < Number(_this.num)) {
          _this.$q.dialog({
            component: CustomComponent,
            componentProps: {
              messages: ["钱包余额不足"],
              persistent: true,
            }
          });
          return
        }
      }

      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ['确定要充值吗?'],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let payWei = _this.web3Helper.toWei(_this.num, 18);
        let outWei = 0;

        _this.$q.loading.show({
          message: _this.$i18n.t("正在交易中...")
        })

        if (lx == 1) {
          _this.web3Helper.transfer(_this.us.address, _this.inAddress, payWei.toString()).then((txid) => {
            _this.$q.loading.hide();
            _this.txid = txid
            _this.showTxid = true
            _this.balance(_this.us.address)
          }, (err) => {
            _this.$q.loading.hide();
            //过滤4001,4001=拒绝签名
            if (err.code != 4001) {
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  title: err.code,
                  messages: [err.message],
                  persistent: true,
                }
              });
            }
          });
        } else if (lx == 2) {
          _this.stcWeb3Helper.contractTransfer(_this.us.address, _this.inAddress, payWei.toString()).then((txid) => {
            _this.$q.loading.hide();
            _this.txid = txid
            _this.showTxid = true
            _this.balance(_this.us.address)
            _this.bscTransactions(txid)
          }, (err) => {
            _this.$q.loading.hide();
            //过滤4001,4001=拒绝签名
            if (err.code != 4001) {
              _this.$q.dialog({
                component: CustomComponent,
                componentProps: {
                  title: err.code,
                  messages: [err.message],
                  persistent: true,
                }
              });
            }
          });
        }
      })
    },
    bscTransactions(hash) {
      let _this = this
      _this.$request.post(
        "api/BscTransactions/Add",
        {
          userid: _this.us.userid,
          hash
        },
        () => {}
      )
    }
  }
}
</script>

<style scoped>
.recharge {
  margin-top: 10px;
  padding: 15px;
  border-radius: 20px;
  background: #121C2E;
}

.title {
  color: #8c95a7;
  font-size: 12px;
}

.consume {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.consume-icon {
  width: 15px;
  height: 15px;
}

.consume-title {
  margin-left: 5px;
  font-size: 13px;
  color: #fff;
}

.form {
  color: #fff;
}

.input-wrap {
  margin-top: 5px;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 99px;
  background-color: #000;
  color: #fff;
}

.input-label {
  font-size: 15px;
  color: #999;
}

.input-row {
  display: flex;
  align-items: center;
}

.stepper-icon {
  width: 22px;
}

.input {
  margin: 0 10px;
  width: 60px;
}

.mintage-text {
  color: #e96dff;
}

.cell {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.btn {
  margin-top: 40px;
  height: 48px;
  line-height: 48px;
  border-radius: 99px;
  text-align: center;
  background: #D51B8C;
  color: #fff;
  font-weight: 15px;
}

.link {
  color: #e96dff;
  text-decoration: underline;
}

.select {
  color: #fff;
}

.select-list {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
}

.select-list-item {
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 180px;
  height: 240px;
  margin: 0 10px 10px 0;
  padding: 10px 0;
  border-radius: 5px;
  background-size: 100% 100%;
  background-image: url(../../../assets/img//touzi/border.png);
}

.active {
  background-size: 100% 100%;
  background-image: url(../../../assets/img//touzi/active.png);
}

.article {
  color: #fff;
  padding: 20px 0;
}

.text-body1 {
  font-size: 16px;
}

.text-body2 {
  font-size: 14px;
  margin-top: 10px;
}

.q-dialog-plugin {
  max-height: calc(100vh - 108px);
  background: #000;
  color: #fff;
  border: 2px solid #f56ef9;
}

.dialog-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  padding: 16px;
}

.btn-row {
  display: flex;
  justify-content: space-between;
}

.btn-row .btn {
  width: 48%;
}

.balance {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8c95a7;
  font-size: 12px
}

.link-text {
  text-align: center;
  margin-top: 15px;
  color: #8c95a7;
  text-decoration: underline;
  font-size: 13px;
}
</style>